import React from 'react';
import {
    Container,
    makeStyles,
    Typography,
    Card,
    CardContent,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Link } from 'react-router-dom';
import {Extension} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    list: {
        maxWidth: '500px'
    },
    icon: {
        minWidth: theme.spacing(4),
        color: 'rgba(0,0,0,0.8)'
    }
}));

const DocumentsView = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const documents = [
        [
            {
                name: "Všeobecné obchodné podmienky NEOSHIP účinné od 27.2.2023",
                file: "obchodne_podmienky_neoship_od_27_2_2023.pdf",
            },
            {
                name: "Cenník NEOSHIP účinný od 17.4.2023",
                file: "cennik_neoship_od_17_04_2023.pdf",
            },
            {
                name: "Fakturácia NEOSHIP",
                file: "fakturacia_neoship.pdf",
            },
            {
                name: "NEOSHIP stanovisko k uplatneniu nárokov",
                file: "stanovisko.pdf",
            }
        ],
        [
            {
                name: "Všeobecné obchodné podmienky SPS od 01.08.2023",
                file: "obchodne_podmienky_SPS_01_08.pdf",
            },
            {
                name: "Všeobecné obchodné podmienky EXPRESS ONE",
                file: "obchodne_ podmienky_INTIME.pdf",
            },
            {
                name: "Všeobecné obchodné podmienky GLS",
                file: "obchodne_podmienky_GLS.pdf",
            },
            {
                name: "Všeobecné obchodné podmienky PACKETA",
                file: "SK_obchodne_podmienky_Packeta.pdf",
            },
            {
                name: "Všeobecné obchodné podmienky 123Kuriér",
                file: "obchodne_podmienky_123kurier.pdf",
            },
        ]
    ];

    const ebooks = [
        {
            name: "EBOOK - Krok za krokom k správnemu expedičnému riešeniu",
            file: "Krok_za_krokom_k_spravnemu_expedicnemu_rieseniu.pdf",
        }
    ];

    const modules = [
        {
            name: "Neoship napojenie na PrestaShop 1.7+",
            file: "prestashop/neoship.zip"
        }
    ];

    return (
        <Page
            title={t('navbar.documents')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.documents')}
                </Typography>
                <Card>
                    <CardContent>
                        {
                            documents.map((section) => (
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    className={classes.list}
                                    dense
                                >
                                    {
                                        section.map((v, index) =>
                                            <ListItem button component={Link} to={"/documents/"+v.file} target="_blank">
                                                <ListItemIcon className={classes.icon}>
                                                    <PictureAsPdfIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={v.name} />
                                            </ListItem>
                                        )
                                    }
                                </List>
                            ))
                        }
                    </CardContent>
                </Card>
                <br />
                <Typography variant="h1" gutterBottom >
                    Ebook
                </Typography>
                <Card>
                    <CardContent>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className="Ebook"
                            dense
                        >
                            {
                                ebooks.map((v, index) =>
                                    <ListItem button component={Link} to={"/documents/"+v.file} target="_blank">
                                        <ListItemIcon className={classes.icon}>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={v.name} />
                                    </ListItem>
                                )
                            }
                        </List>
                    </CardContent>
                </Card>
                <br />
                <Typography variant="h1" gutterBottom >
                    {t('title.modules')}
                </Typography>
                <Card>
                    <CardContent>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            dense
                        >
                            {
                                modules.map((v, index) =>
                                    <ListItem button component={Link} to={"/documents/"+v.file} target="_blank">
                                        <ListItemIcon className={classes.icon}>
                                            <Extension />
                                        </ListItemIcon>
                                        <ListItemText primary={v.name} />
                                    </ListItem>
                                )
                            }
                        </List>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default DocumentsView;