import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
    Box
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import AppContext from 'context/AppContext';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ShipperPickupAddressForm from "./ShipperPickupAddressForm";
import {getAllPickups} from "../../../helpers";

export default function ShipperOthersForm({ user, selectedShipper, ...rest }) {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});
    const { appValues } = useContext(AppContext);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckBoxChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();
        let tmpValues = {...values};
        const pickupDependentFields = ['K123_SENDER_ID', 'PACKETA_SENDER_ID'].filter(field => Object.keys(tmpValues).includes(field))[0];
        if(pickupDependentFields && typeof tmpValues[pickupDependentFields] === 'object'){
            tmpValues[pickupDependentFields] = JSON.stringify(tmpValues[pickupDependentFields]);
        }
        secureFetch(getApiRoute('admin_user_shippersettings', [user.id, selectedShipper.shipper_id]), tmpValues, 'POST').then(json => {
            setSuccessMessage(t('messages.data_saved'));
        });
    };

    useEffect(() => {
        secureFetch(getApiRoute('admin_user_shippersettings', [user.id, selectedShipper.shipper_id])).then(json => {
            if (selectedShipper.type === 'fix') {
                setValues({...json, [selectedShipper.shortcut.toUpperCase()+'_COD_STATES']: appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code)).map((state) => state.code)});
            } else {
                const pickupDependentFields = ['K123_SENDER_ID', 'PACKETA_SENDER_ID'].filter(field => Object.keys(json).includes(field))[0];
                if(pickupDependentFields && ['string', 'number'].includes(typeof json[pickupDependentFields])){
                    const senderId = json[pickupDependentFields];
                    json[pickupDependentFields] = getAllPickups(user).reduce((obj, addr) => Object.assign(obj, {[addr.id]: Object.keys(obj).length ? '' : senderId}), {});
                }
                setValues(json);
            }
        });
    }, [secureFetch, user.id, selectedShipper.shipper_id]);

    return (
        <form onSubmit={handleSubmitForm}>
            <Box p={1} />
            <Typography variant="h6" gutterBottom >
                {t('title.settings')}
            </Typography>
            {
                selectedShipper.shortcut.toUpperCase() === '123' && selectedShipper.type !== 'fix' &&
                <React.Fragment>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={'API - ' + t('label.name')}
                                name={'K123_API_USERNAME'}
                                onChange={handleChange}
                                value={values['K123_API_USERNAME'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={'API - ' + t('label.password')}
                                name={'K123_API_PASSWORD'}
                                onChange={handleChange}
                                value={values['K123_API_PASSWORD'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <ShipperPickupAddressForm
                        user={user}
                        shipper={selectedShipper}
                        label={t('label.sender_address_id')}
                        name={'K123_SENDER_ID'}
                        onChange={handleChange}
                        values={values}
                    />
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <Autocomplete
                                multiple
                                options={appValues.states.filter((state) => ['SK', 'CZ', 'HU'].includes(state.code) )}
                                onChange={ (event, pStates) => setValues({ ...values, 'K123_COD_STATES': pStates.map((state) => state.code) }) }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={values['K123_COD_STATES'] ? appValues.states.filter((state) => values['K123_COD_STATES'].includes(state.code)) : [] }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('label.states_cod_to')} placeholder={t('label.state')} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {
                selectedShipper.shortcut.toUpperCase() === 'GLS' && selectedShipper.type !== 'fix' &&
                <React.Fragment>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={t('label.client_number')}
                                name={'GLS_CLIENT_NUMBER'}
                                onChange={handleChange}
                                value={values['GLS_CLIENT_NUMBER'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!values['GLS_ALLOW_CARD']}
                                        onChange={handleCheckBoxChange}
                                        name='GLS_ALLOW_CARD'
                                        color="primary"
                                    />
                                }
                                label={t('label.card_payment')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <Autocomplete
                                multiple
                                options={appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code) )}
                                onChange={ (event, pStates) => setValues({ ...values, GLS_COD_STATES: pStates.map((state) => state.code) }) }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={values['GLS_COD_STATES'] ? appValues.states.filter((state) => values['GLS_COD_STATES'].includes(state.code)) : [] }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('label.states_cod_to')} placeholder={t('label.state')} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }

            {
                selectedShipper.shortcut.toUpperCase() === 'PACKETA' && selectedShipper.type !== 'fix' &&
                <ShipperPickupAddressForm
                    user={user}
                    shipper={selectedShipper}
                    label={t('label.sender_id')}
                    name={'PACKETA_SENDER_ID'}
                    onChange={handleChange}
                    values={values}
                />
            }

            {
                selectedShipper.shortcut.toUpperCase() === 'SPS' && selectedShipper.type !== 'fix' &&
                <React.Fragment>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={'API - ' + t('label.name')}
                                name={'SPS_API_USERNAME'}
                                onChange={handleChange}
                                value={values['SPS_API_USERNAME'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={'API - ' + t('label.password')}
                                name={'SPS_API_PASSWORD'}
                                onChange={handleChange}
                                value={values['SPS_API_PASSWORD'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={t('label.local_number')}
                                name={'SPS_LOCAL_NUMBER'}
                                onChange={handleChange}
                                value={values['SPS_LOCAL_NUMBER'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={t('label.international_number')}
                                name={'SPS_INTERNATIONAL_NUMBER'}
                                onChange={handleChange}
                                value={values['SPS_INTERNATIONAL_NUMBER'] || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <Autocomplete
                                multiple
                                options={appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code) )}
                                onChange={ (event, pStates) => setValues({ ...values, SPS_COD_STATES: pStates.map((state) => state.code) }) }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={values['SPS_COD_STATES'] ? appValues.states.filter((state) => values['SPS_COD_STATES'].includes(state.code)) : [] }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('label.states_cod_to')} placeholder={t('label.state')} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {
                selectedShipper.type === 'fix' &&
                <React.Fragment>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <Autocomplete
                                disabled
                                multiple
                                options={appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code) )}
                                onChange={ (event, pStates) => setValues({ ...values, [selectedShipper.shortcut.toUpperCase()+'_COD_STATES']: pStates.map((state) => state.code) }) }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={appValues.states.filter((state) => ['SK', 'CZ', 'HU', 'HR', 'RO', 'SI'].includes(state.code)) }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('label.states_cod_to')} placeholder={t('label.state')} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {
                successMessage === '' ? null :
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item lg={4} md={6} xs={12}>
                            <Alert variant="filled" severity="success">
                                {successMessage}
                            </Alert>
                        </Grid>
                    </Grid>
            }
            <Box p={1} />
            <Button type="submit" color="primary" variant="contained">{t('button.save')}</Button>
        </form>
    );
}