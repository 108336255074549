import React, { useContext, useEffect, useCallback } from 'react';
import {
    Container,
    Typography,
    Grid,
    makeStyles,
    Box
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeliveryAddressForm from './DeliveryAddressForm';
import InvoiceAddressForm from './InvoiceAddressForm';
import PasswordForm from './PasswordForm';
import BasicSettingsForm from './BasicSettingsForm';
import SecureFetchContext from 'context/SecureFetchContext';
import { isNumeric } from "helpers";
import { useParams, useNavigate } from "react-router-dom";
import getApiRoute from 'routes/apiRoutes';
import TabPanel from 'components/TabPanel';
import Shipper from './Shipper';
import Credit from './Credit';
import OtherSettingsForm from 'views/AccountView/OtherSettingsForm';
import UserLogo from 'views/AccountView/UserLogo';
import EmailTemplateSelector from "../../AccountView/EmailTemplateSelector";
import ImportAddressForm from './ImportAddressForm';
import Discounts from "./Discounts";
import ExpeditionaryAddressForm from "./ExpeditionaryAddressForm";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 'initial',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    appBar:{
        background: 'white'
    }
}));

export default function UserEditView() {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [user, setUser] = React.useState({username: ''});
    let { id, tab } = useParams();
    tab = tab ? tab : '';
    let navigate = useNavigate();

    const handleChange = (event, newValue) => {
        navigate("/app/admin/user/edit/"+user.id+"/"+newValue);
    };

    const loadUser = useCallback((pConditions = null) => {
        secureFetch( getApiRoute('admin_user', [id]) ).then(json => {
            console.log(json)
            setUser(json);
        });
    }, [secureFetch, id])

    useEffect(() => {
        if ( !isNumeric(id) ) {
            navigate('/404');
        }
        if (user.id !== parseInt(id)) {
            loadUser();
        }
    }, [loadUser, navigate, user.id, id]);

    return (
        <Page
            className={classes.root}
            title={t('title.edit_user') + ' - ' + user.username}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('title.edit_user') + ' - ' + user.username + ' ' + parseFloat(user.credit).toFixed(2) + '€'}
                </Typography>
                <div className={classes.root}>
                    <AppBar className={classes.appBar} position="static" color="default">
                        <Tabs 
                            value={tab}
                            onChange={handleChange}
                            aria-label="scrollable auto tabs"
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            <Tab value="" label={t('title.basic_settings')} />
                            <Tab value="credit" label={t('title.credit')} />
                            <Tab value="shipper" label={t('title.shippers')} />
                            <Tab value="email" label={t('title.emails')} />
                            <Tab value="other_settings" label={t('title.other_settings')} />
                            <Tab value="discounts" label={t('title.discounts')} />
                        </Tabs>
                    </AppBar>
                    <TabPanel className={classes.tabPanel} value={tab} index={""}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={8}>
                                <BasicSettingsForm user={user} />
                                {
                                    user.roles && !user.roles.includes('ROLE_ADMIN') &&
                                    <React.Fragment>
                                        <Box pb={3} />
                                        <InvoiceAddressForm
                                            loadUser={loadUser}
                                            user={user}
                                        />
                                        <Box pb={3} />
                                        <ExpeditionaryAddressForm
                                            loadUser={loadUser}
                                            user={user}
                                        />
                                        <Box pb={3} />
                                        <DeliveryAddressForm
                                            user={user}
                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <UserLogo user={user} loadUser={loadUser} />
                                <Box pb={3} />
                                <PasswordForm user={user} />
                                <Box pb={3} />
                                <ImportAddressForm user={user} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={tab} index={"credit"}>
                        <Credit user={user} />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={tab} index={"shipper"}>
                        <Shipper user={user} />
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={tab} index={"email"}>
                        {
                            user.username !== '' && <EmailTemplateSelector user={user} setUser={setUser} />
                        }
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={tab} index={"other_settings"}>
                        {
                            user.username !== '' && <OtherSettingsForm user={user} />
                        }
                    </TabPanel>
                    <TabPanel className={classes.discounts} value={tab} index={"discounts"}>
                        {
                            user.username !== '' && <Discounts user={user} />
                        }
                    </TabPanel>
                </div>
            </Container>
        </Page>
    );
}