import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useLocation } from "react-router-dom";
import {
    makeStyles
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import Page from 'components/Page';
import {
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getShipperLogoPath } from "helpers";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import sk from "date-fns/locale/sk";
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    position: {
        height: '50px',
        minWidth: '45px'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    card: {
        height: "100%"
    },
    boxAlert: {
        maxWidth: 300
    },
    selected: {
        border: "4px solid black"
    }
}));

const shippersPrintTypes = {
    GLS: {
        'A4_2x2': 'A4 - 2x2',
        'A4_4x1': 'A4 - 4x1',
        'Connect': 'Connect',
        'Thermo': 'Thermo'
    },
    SPS: {
        'a4': 'A4',
        'a6': 'A6',
        'thermal_58': 'Thermal 58'
    },
    PACKETA: {
        'A6 on A4': 'A6 na A4 - 4 štítky na A4 (105x 148 mm)',
        'A6 on A6': 'A6 na A6 - Zebra štítok (105x 148 mm)',
        'A7 on A7': 'A7 na A7 - Zebra štítok (105x 74 mm)',
        'A7 on A4': 'A7 na A4 - 8 štítkov na A4 (105x74 mm)',
        'A8 on A8': 'A8 na A8 - Zebra štítok (50x74 mm)',
        '105x35mm on A4': '105x 35 mm na A4 - 16 štítkov na A4 (105x35 mm)'
    },
    PACKETAHD: {
        'A6 on A4': 'A6 na A4 - 4 štítky na A4 (105x 148 mm)',
        'A6 on A6': 'A6 na A6 - Zebra štítok (105x 148 mm)'
    },
    "123": {
        'A4': 'A4',
        'ZEBRA': 'ZEBRA',
        'ZPL': 'ZPL',
        'ZPL300': 'ZPL300'
    },
    DPD: {
        'A4': 'A4',
        'A6': 'A6',
        //'zpl': 'ZPL',
        //'epl': 'EPL',
        //'bmp': 'BMP'
    }
}

const PackagePrintSticker = () => {
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [stickers, setStickers] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [openStickerDialog, setOpenStickerDialog] = React.useState(false);
    const [loading, setLoading] = React.useState({});
    const [printType, setPrintType] = React.useState(null);
    const [stickerPosition, setStickerPosition] = React.useState(1);
    const [shipper, setShipper] = React.useState(null);
    const [reverseSuccess, setReverseSuccess] = React.useState({});
    const [docFormat, setDocFormat] = React.useState('application/pdf');
    const classes = useStyles();
    const { state } = useLocation();
    const [pickupDate, setPickupDate] = React.useState((new Date()).toISOString().slice(0, 10));

    const processPackageData = () => {
        let tmpPackages = {};
        for (const key in state) {
            let shipper = state[key].shipper.toUpperCase();
            if (shipper === 'PACKETA' && state[key].carrier_type !== null){
                shipper = 'PACKETAHD';
            }
            if (!tmpPackages[shipper]) {
                tmpPackages[shipper] = [];
            }
            tmpPackages[shipper].push(state[key]);
        }
        return tmpPackages;
    };

    const packagesByShipper = processPackageData();

    const sendBulkAction = () => {
        setOpenStickerDialog(false);
        let values = {
            action: 'send_print_sticker',
            packages: packagesByShipper[shipper].map((val) => val.id),
            sticker_position: stickerPosition,
            print_type: printType,
            source: 'WEB'.toUpperCase(),
            pickup_date: pickupDate,
        }
        secureFetch(getApiRoute('package_bulk'), values, 'POST').then(json => {
            setStickers({ ...stickers, [shipper]: json['labels']});
            setErrors({...errors, [shipper]: json['errors']});
            setOpenStickerDialog(false);
            setLoading(false);

            if (json['labels'] && json['labels'] !== '') {
                let a = document.createElement('a');
                a.href = 'data:'+docFormat+';base64,' + json['labels'];
                a.download = "stickers." + (docFormat === 'text/plain' ? 'txt' : 'pdf');
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            }
            if (!json['labels'] && json['errors'].length === 0) {
                setReverseSuccess({ ...reverseSuccess, [shipper]: true });
            }
        });
    };

    const printStickers = (shipper) => {
        setLoading({...loading, [shipper]: true});
        setShipper(shipper.toUpperCase());
        setPrintType(Object.keys(shippersPrintTypes[shipper])[0]);
        setDocFormat(['ZPL', 'ZPL300'].includes(Object.keys(shippersPrintTypes[shipper])[0]) ? 'text/plain' : 'application/pdf')
        setOpenStickerDialog(true);
    };
    
    const onDialogClose = () => {
        setLoading(false);
        setOpenStickerDialog(false);
    };

    const onRepairClick = (pShipper, package_id, shipper_id) => {
        for (let index = 0; index < errors[pShipper].length; index++) {
            if (errors[pShipper][index].package_id === package_id) {
                errors[pShipper].splice(index, 1);
                break;
            }
        }
        if (errors[pShipper].length === 0) {
            errors[pShipper] = undefined;
        }
        setErrors({...errors})
        window.open(`/app/package/edit/${package_id}/${shipper_id}?next=close`, '_blank')
    }

    const getPackageCount = (packages) => {
        let sum = 0;
        for (const p of packages) {
            sum += 1;
            sum += p.subpackages.count;
        }
        return sum;
    }

    return (
        <Page
            className={classes.root}
            title={t('navbar.packages')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.packages')}
                </Typography>
                <Grid container spacing={3}>
                    {
                        Object.keys(packagesByShipper).map((pShipper, index) => {
                            return (
                                <Grid item key={index}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Box p={3} textAlign="center">
                                                <img src={getShipperLogoPath(pShipper)} alt={pShipper} height={50} />
                                            </Box>
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left">{t('label.count_of_packages')}</TableCell>
                                                        <TableCell variant="head" align="right">{getPackageCount(packagesByShipper[pShipper])}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Box pb={3} />
                                            {
                                                stickers[pShipper] &&
                                                <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        startIcon={<PrintIcon />}
                                                        href={'data:'+docFormat+';base64,' + stickers[pShipper]}
                                                        fullWidth
                                                        download
                                                    >
                                                        {t('button.print_sticker')}
                                                </Button>
                                            }
                                            {
                                                !stickers[pShipper] && !errors[pShipper] &&
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    startIcon={<PrintIcon />}
                                                    onClick={() => printStickers(pShipper)}
                                                    disabled={loading[pShipper] ? true : false}
                                                    fullWidth
                                                >
                                                    {t('button.print_sticker')}
                                                    {loading[pShipper] && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                </Button>
                                            }
                                            {
                                                Array.isArray(errors[pShipper]) && errors[pShipper].map((err, index) =>
                                                    <Box pt={2} className={classes.boxAlert} key={index}>
                                                        <Alert variant="filled" severity="error">
                                                            {err.reference_number}: {err.errors.join(',')}
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                className={classes.button}
                                                                startIcon={<EditIcon />}
                                                                onClick={() => onRepairClick(pShipper, err.package_id, err.shipper_id)}
                                                                fullWidth
                                                            >
                                                                {t('button.repair')}
                                                            </Button>
                                                        </Alert>
                                                    </Box>
                                                )
                                            }
                                            {
                                                typeof errors[pShipper] === 'string' &&
                                                <Box pt={2} className={classes.boxAlert} key={index}>
                                                    <Alert variant="filled" severity="error">
                                                        {errors[pShipper]}
                                                    </Alert>
                                                </Box>
                                            }
                                            {
                                                reverseSuccess[pShipper] &&
                                                <Box pt={2} className={classes.boxAlert} key={index}>
                                                    <Alert variant="filled" severity="success">
                                                        {t('messages.package_send_success')}
                                                    </Alert>
                                                </Box>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            
                        <Snackbar open={successMessage !== null} autoHideDuration={3000} onClose={() => setSuccessMessage(null)}>
                            <Alert onClose={() => setSuccessMessage(null)} severity="success">
                                {successMessage}
                            </Alert>
                        </Snackbar>
                      
                        {shipper && <Dialog
                            open={openStickerDialog}
                            onClose={onDialogClose}
                            maxWidth="xs"
                            fullWidth={true}
                            aria-labelledby="sticker-dialog-title"
                            aria-describedby="sticker-dialog-description"
                        >
                            <DialogTitle id="sticker-dialog-title">{t('title.settings')}</DialogTitle>
                            <DialogContent>
                                {
                                    <React.Fragment>
                                        <Box display="flex">
                                            <Box width="50px">
                                                <Button onClick={() => setStickerPosition(1)} color="primary" variant="contained" className={`${classes.position} ${stickerPosition === 1 ? classes.selected : ''}`}>
                                                    1
                                                </Button>
                                            </Box>
                                            <Box width="50px" pl={0.5}>
                                                <Button onClick={() => setStickerPosition(2)} color="primary" variant="contained" className={`${classes.position} ${stickerPosition === 2 ? classes.selected : ''}`}>
                                                    2
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box display="flex" pt={1}>
                                            <Box width="50px">
                                                <Button onClick={() => setStickerPosition(3)} color="primary" variant="contained" className={`${classes.position} ${stickerPosition === 3 ? classes.selected : ''}`}>
                                                    3
                                                </Button>
                                            </Box>
                                            <Box width="50px" pl={0.5}>
                                                <Button onClick={() => setStickerPosition(4)} color="primary" variant="contained" className={`${classes.position} ${stickerPosition === 4 ? classes.selected : ''}`}>
                                                    4
                                                </Button>
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                }
                                <Box pt={2}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="printtype-label">{t('label.print_type')}</InputLabel>
                                        <Select
                                            labelId="printtype-label"
                                            id="printtype"
                                            value={printType}
                                            onChange={(e) => {
                                                setPrintType(e.target.value)
                                                setDocFormat(['ZPL', 'ZPL300'].includes(e.target.value) ? 'text/plain' : 'application/pdf')
                                            }}
                                        >
                                            {
                                                Object.keys(shippersPrintTypes[shipper]).map((key, index) => <MenuItem key={index} value={key}>{shippersPrintTypes[shipper][key]}</MenuItem> )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                {['123', 'DPD'].includes(shipper) &&
                                    <Box pt={2}>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <LocalizationProvider dateAdapter={DateFnsAdapter} locale={sk}>
                                                <DatePicker
                                                    autoOk
                                                    ampm={false}
                                                    disablePast
                                                    onChange={(val) => setPickupDate(val)}
                                                    value={pickupDate || ''}
                                                    label="Dátum zvozu (iba pracovné dni)"
                                                    inputFormat="dd.MM.yyyy"
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        fullWidth
                                                        required
                                                    />}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={sendBulkAction} color="primary">
                                    {t('button.print_sticker')}
                                </Button>
                                <Button onClick={onDialogClose} color="secondary">
                                    {t('button.close')}
                                </Button>
                            </DialogActions>
                        </Dialog>}
            </Container>
        </Page>
    );
}

export default PackagePrintSticker;