import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Tooltip, makeStyles, Box
} from '@material-ui/core';
import getApiRoute from 'routes/apiRoutes';
import {EventAvailable} from "@material-ui/icons";
import {getDefaultAddress, getShipperLogoPath} from "../../../helpers";
import AppContext from "../../../context/AppContext";
import PackagePrintProtocolFromDate from "./PackagePrintProtocolFromDate";

const useStyles = makeStyles((theme) => ({
    root: {},
    button: {
        marginRight: "10px"
    },
    boxItem: {
        flex: '0 0 150px'
    }
}));

export default function DailyClosingDialog({ setErrors, loadPackages, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { appValues } = useContext(AppContext);
    const secureFetch = useContext(SecureFetchContext);
    const [open, setOpen] = React.useState(false);
    const [selectedShipper, setSelectedShipper] = React.useState(null);
    const [spsProtocol, setSpsProtocol] = React.useState(null);

    const [date, setDate] = React.useState((new Date()).toISOString().slice(0, 10));
    const [pickup, setPickup] = React.useState(getDefaultAddress(appValues.user));

    const spsEnabled = appValues.user.user_shipper_price_lists.find(v => v.shipper.shortcut === 'SPS');
    const packetaEnabled = appValues.user.user_shipper_price_lists.find(v => v.shipper.shortcut === 'Packeta');
    const k123Enabled = appValues.user.user_shipper_price_lists.find(v => v.shipper.shortcut === '123');

    const dailyClosing = () => {
        let values = {
            action: 'daily_closing',
        }
        secureFetch(getApiRoute('package_bulk'), values, 'POST').then(json => {
            if (json.protocol) {
                let a = document.createElement('a');
                a.href = 'data:application/pdf;base64,' + json.protocol;
                a.download = "protocol.pdf";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setSpsProtocol(json.protocol);
            }
            if (json.errors !== '') {
                setErrors(json.errors);
            }
        });
    };

    const ACTIONS_MAPPER = {
        '123': 'k123_acceptance_protocol',
        'PACKETA': 'packeta_acceptance_protocol'
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {action: ACTIONS_MAPPER[selectedShipper], pickup: pickup.id};
        secureFetch(getApiRoute('package_bulk'), {date, ...payload}, 'POST').then(json => {
            handleClose();
            if (json.protocol) {
                let a = document.createElement('a');
                a.href = 'data:application/pdf;base64,' + json.protocol;
                a.download = "protocol.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                loadPackages();
            } else {
                if (json.errors && json.errors !== '') {
                    setErrors(json.errors);
                }
            }
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShipperChange = (shipper) => {
        setSelectedShipper(shipper);
    }

    return (
        <React.Fragment>
            <Tooltip title={t('button.daily_closing')} aria-label={t('button.daily_closing')}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: '#5f3f25'}}
                    size="small"
                    startIcon={<EventAvailable />}
                    aria-label={t('button.daily_closing')}
                    onClick={handleClickOpen}
                    className={classes.button}
                >
                    {t('button.daily_closing')}
                </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus >
                <DialogTitle>{t('button.daily_closing')}</DialogTitle>
                <DialogContent>
                    { !selectedShipper &&
                        <React.Fragment>
                            <DialogContentText >
                                Vyberte prepravcu pre ktorého chcete vykonať dennú uzáveru a vytlačiť preberací protokol.
                            </DialogContentText>
                            <div style={{width: '100%'}}>
                                <Box
                                    display='flex'
                                    flexDirection='row'
                                    flexWrap='nowrap'
                                    justifyContent='space-evenly'
                                    alignItems='center'
                                    alignContent='stretch'
                                >
                                    {
                                        spsEnabled &&
                                        <Box className={classes.boxItem}>
                                            <Button
                                                fullWidth
                                                href={spsProtocol ? 'data:application/pdf;base64,' + spsProtocol : null}
                                                onClick={spsProtocol ? null : dailyClosing}
                                                download={!!spsProtocol}
                                            >
                                                <img width='100%' src={getShipperLogoPath('SPS')} alt={'SPS'}/>
                                            </Button>
                                        </Box>
                                    }
                                    {
                                        packetaEnabled &&
                                        <Box className={classes.boxItem}>
                                            <Button fullWidth onClick={() => handleShipperChange('PACKETA')}>
                                                <img width='100%' src={getShipperLogoPath('PACKETA')} alt={'Packeta'}/>
                                            </Button>
                                        </Box>
                                    }
                                    {
                                        k123Enabled &&
                                        <Box className={classes.boxItem}>
                                            <Button fullWidth onClick={() => handleShipperChange('123')}>
                                                <img width='100%' style={{maxWidth: '100%'}} src={getShipperLogoPath('123')} alt={'123Kuriér'}/>
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                            </div>
                        </React.Fragment>
                    }
                    {
                        selectedShipper === 'PACKETA' &&
                        <PackagePrintProtocolFromDate
                            buttonTitle={t('button.packeta_acceptance_protocol')}
                            dialogTitle={t('tooltip.print_packeta_protocol')}
                            setErrors={setErrors}
                            loadPackages={loadPackages}
                            handleClose={handleClose}
                            date={date}
                            setDate={setDate}
                            selectedShipper={selectedShipper}
                            pickup={pickup}
                            setPickup={setPickup}
                        />
                    }
                    {
                        selectedShipper === '123' &&
                        <PackagePrintProtocolFromDate
                            buttonTitle={t('button.k123_acceptance_protocol')}
                            dialogTitle={t('tooltip.print_123_protocol')}
                            setErrors={setErrors}
                            loadPackages={loadPackages}
                            handleClose={handleClose}
                            date={date}
                            setDate={setDate}
                            selectedShipper={selectedShipper}
                            pickup={pickup}
                            setPickup={setPickup}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    {selectedShipper &&
                    <Button color="primary" variant="text" onClick={() => handleShipperChange(null)} >
                        {t('button.back')}
                    </Button>
                    }
                    <Button color="secondary" variant="contained" onClick={handleClose} >
                        {t('button.close')}
                    </Button>
                    {selectedShipper &&
                    <Button color="primary" variant="contained" onClick={handleSubmit} >
                        {t('button.process')}
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
